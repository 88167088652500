.general-button{
    padding:2px 10px;
    border-radius:4px;
    transition: all 0.4s;
}

.custom-button {
    background-color: rgb(3, 57, 73);
    color: rgb(220, 239, 245);
    border: 2px solid rgb(3, 57, 73);
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-button:hover {
    background-color: rgb(220, 239, 245);
    color: rgb(3, 57, 73);
}

.custom-button:active {
    transform: scale(0.95);
}

.info-button{
    color:rgb(3, 57, 73);
    background-color: rgb(221, 235, 239);
    border:1px solid rgb(3, 57, 73);
}

.delete-button{
    background-color:#f598a6;
    color:#fff;
    border:1px solid #f598a6;
}

.delete-button:hover{
    color:#f598a6;
    background-color:#fff;
}

.info-button:hover{
    background-color: rgb(3, 57, 73);
    color:#fff;
}

.right{
    float: right;
}

.left{
    float: left;
}