textarea,select,input[type="password"],input[type="text"],input[type="email"],input[type="date"],input[type="number"]{
  display: block;
  padding:10px;
  width:100%;
  margin:10px;
  box-sizing:border-box;
  border-radius:8px;
  border:1px solid rgb(182, 182, 182)
}
textarea{
  resize: vertical;
  min-height: 150px;
  max-height: 250px;
}
label{
  font-size:12px;
  margin-left:10px;
  display:block;
}
input:focus{
  border:2px solid rgb(195, 222, 230);
  outline:none;
}
input[type="checkbox"]{
  margin:10px;
}
.login-button{
  display: block;
  margin:15px auto;
  padding:10px;
  width:50%;
  font-size:1.1rem;
  border-radius:8px;
}
.primary, .secondary {
  background-color: rgb(195, 222, 230);
  border: 1px solid rgb(195, 222, 230);
  color: #fff;
  transition: 0.5s all;
  padding: 10px;
  border-radius: 4px;
  position: relative;  /* Añadido para permitir la animación del pseudo-elemento */
  overflow: hidden;    /* Añadido para asegurar que el pseudo-elemento no desborde */
}
.secondary{
  background-color:rgb(126, 171, 185);
  background-color:rgb(126, 171, 185);
}

.primary::before, .secondary::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: all 0.7s ease;
  transform: translate(-50%, -50%) scale(0);
}

.primary:hover::before, .secondary:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.primary:hover, .secondary:hover {
  background-color: rgb(170, 200, 210);
  border: 1px solid rgb(170, 200, 210);
}


hr{
  border:1px solid #dcdcdc
}

