.parent-active-account{
    background-image: url('./../../assets/images/active-account.jpeg');
    height:1300px;
    background-size:cover;            
    background-position:center;      
    background-color:rgb(72, 103, 113);
    /* background-color:#5d7979; */
    background-blend-mode:  soft-light; 
}
.child{
    padding:250px;
}
.child h1,.child p{
    /* border-radius: 70%; */
    display: block;
    color:#fff;
    font-size:60px;
    text-shadow: 3px 3px 3px #000;
    animation: expand .5s ease-in-out;
}
.span-init-session{
    display: block;
    margin-top:15px;
    text-align:center;
    padding:10px;
    width:50%;
    font-size:1.1rem;
    border-radius:8px;
    animation: expand .5s ease-in-out;
}
.child p{
    font-size:40px;
}
.child-error-link{
    padding-top:200px;
}
.child-error-link div{
    background-color: #fff;
    padding:30px;
    margin:0 auto;
    width:70%;
}
@keyframes expand {
    from {
      transform: scale(0);
      opacity: 0;
    }
}