.menu-horizontal{
    background-color:rgb(220, 239, 245);
    text-align: right;
}

.menu-horizontal ul li{
    display: inline-block;
    padding:10px 15px;
    color:rgb(129, 175, 188);
    transition: all 0.5s ease;
    border-left: 1px solid rgb(129, 175, 188);
}

.menu-horizontal ul li:hover{
    background-color:rgb(129, 175, 188);
    color:rgb(220, 239, 245);
}

.menu-horizontal ul li .tooltip{
    display:inline-block;
    margin-left:5px;
}

.menu-horizontal ul .active{
    background-color:rgb(129, 175, 188);
    color:rgb(220, 239, 245);
}

.menu-horizontal ul .active .icon-menu{
    color:rgb(220, 239, 245);
}

.menu-horizontal{
    width:100%;
}
