.general-confirm{
    position: absolute;
    text-align: center;
    width:100%;
    background-color: #fff;
    top:0;
    left:0;
    padding:10px 0px;
    overflow: hidden;
    animation: sliceDown 1s forwards;
}
.warning{
    border:1px solid goldenrod;
    background-color: rgb(238, 223, 186);
}
.yes-confirmation{
    background-color: rgb(64, 134, 17);
    color:#fff;
    border:1px solid rgb(64, 134, 17);
}

.no-confirmation{
    background-color: rgb(48, 46, 46);
    border:rgb(48, 46, 46);
    color:#fff;
}

.slice-top{
    animation: sliceTop 1s forwards;
    overflow: hidden;
}

@keyframes sliceDown {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes sliceTop {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

