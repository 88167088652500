* {
	margin: 0;
	padding: 0;
	list-style: none;
	font-family: 'Lato', sans-serif;
	line-height: 1;
}

body {
	background-color: #F5F6F8;
}


.sidebar-navigation {
	display: block;
	min-height: 100vh;
    height: auto;
	width: 80px;
	background-color:rgb(3, 57, 73);
	float: left;
}

.sidebar-navigation ul {
    text-align: center;
    color: white;
}
.sidebar-navigation li {
    padding: 18px 0;
    cursor: pointer;
    transition: all ease-out 120ms;	
}

.sidebar-navigation li:hover,.active {
    background-color:rgb(195, 222, 230);
}
.sidebar-navigation li:hover .icon-menu,.active .icon-menu{
    color:rgb(3, 57, 73);
}

.sidebar-navigation li:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.sidebar-navigation .icon-menu {
    display: block;
    margin:0px auto;
    font-size: 30px;
    color:rgb(195, 222, 230);
    transition: all ease 450ms;
}

.sidebar-navigation .tooltip {
    display: inline-block;
    position: absolute;
    background-color:rgb(195, 222, 230);
    color:rgb(3, 57, 73);
    padding: 8px 15px;
    border-radius: 3px;
    margin-top: -26px;
    left: 90px;
    opacity: 0;
    visibility: hidden;
    font-size: 13px;
    letter-spacing: .5px;
}

.sidebar-navigation .tooltip::before {
    content: '';
    display: block;
    position: absolute;
    background-color:rgb(3, 57, 73);
    left: -4px;
    top: 10px;
    transform: rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: inherit;
}

.active .icon-menu{
    color:rgb(3, 57, 73);
}

@media only screen and (max-width: 1318px) {
    .sidebar-navigation {
      display: none;
    }
}