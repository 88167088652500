.div-starts{
    margin:10px;
}

.type-gilds{
    display:none;
}

.show-type-operator{
    display:block;
}
.label-guild{
    display:none;
}
.mandatory{
    font-size:15px;
    color: #f598a6;
}
.error-form{
    color:#f598a6;
    margin:10px
}
.no-mandatory{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5px; /* Ancho y alto del círculo */
    height: 5px;
    color:rgb(174, 174, 174);
    border: 1px solid rgb(174, 174, 174); /* Grosor y color del borde */
    border-radius: 50%; /* Hace que el borde sea redondeado */
    background-color: white; /* Color de fondo del círculo */
    padding: 3px;
}

.assessment{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    margin-top:25px;
}

.assessment div{
    background-color:rgb(195, 222, 230);
    padding:10px;
    text-align: center;
    border-radius:4px;
    color:rgb(3, 57, 73);
   
}

@media only screen and (max-width:883px){
    .assessment div{
        width:80%;
    }
}

@media only screen and (max-width:1011px){
    .comment-box-service{
        margin-top: 30px;
    }
}