/* OperatorCard.css */

.operators-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
  }
  
  .operator-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 16px;
    overflow: hidden;
    transition: transform 0.3s ease;
  }

  .telephon {
    color: rgb(3, 57, 73);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  .operator-card:hover {
    transform: translateY(-5px);
  }
  
  .operator-card-header {
    background: rgb(100, 149, 237);
    color: #fff;
    padding: 16px;
    text-align: center;
  }
  
  .operator-card-header h2 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .operator-card-header p {
    margin: 4px 0 0;
    font-size: 0.9em;
  }
  
  .operator-card-content {
    padding: 16px;
  }
  
  .operator-card-content p {
    margin: 8px 0;
    font-size: 0.9em;
    color: #555;
  }
  
  .operator-card-content p strong {
    color: #333;
  }
  
  .comment-box {
    background-color: #f9f9f9; /* Color de fondo similar a Facebook */
    border-radius: 8px;
    padding: 12px;
    margin-top: 12px;
  }
  
  .comment-box{
    margin: 0;
    font-size: 0.9em;
    color: #555;
    line-height: 1.2
  }
  .comment{
    display: block;
    text-align: center;
    text-decoration: underline;
    margin-bottom:10px!important;
  }