.parent-register{
    background-image: url('./../../assets/images/register-image.jpeg');
    background-size:cover;            
    background-position:center;      
    background-color:#5d7979;
    background-blend-mode:  soft-light; 
    vertical-align:top;
    box-sizing: border-box;
    height:auto;
    min-height:1200px;
    align-items:center;
    justify-content: center;
}
.parent-form{
    display: block;
    width:60%;
    padding-top: 100px;
    margin:0px auto;
    padding-bottom: 100px;
}
.form-register{
    background-color:#fff;
    box-sizing:content-box;
    padding:30px;
    border-radius:4px;
    opacity:0.9;
}
.form-register h1{
    text-align: center;
    margin-bottom:30px;
    font-size:30px;
}
.span-icon{
    display: block;
    text-align:right;
    position:relative;
}
.icon-form{
    position:absolute;
    right:0;
    top:10px;
    font-size:20px;
    color:rgb(195, 222, 230);
    cursor:pointer;
}
.hidde-icon{
    display:none;
}

.terms{
    border:1px solid rgb(195, 222, 230);
    display:block;
    width:60%;
    margin:10px auto;
    padding:15px;
    color:rgb(150, 150, 150);
    line-height: 1.3;
    border-radius:4px;
}
.paragraph-error{
    color:#f598a6;
    margin-left:20px;
    margin-bottom:10px;
}
.accept-error{
    text-align: center;
}

@media only screen and (max-width:1132px){
    .parent-form{
        width:90%;
    }
}

@media only screen and (max-width:768px){
    .terms{
        width:90%;
    }
}