.content-component{
    width: 100%;
    border-bottom: 1px solid #dcdcdc;
    min-height:100vh!important;
}

@media only screen and (max-width: 1318px) {
    .content-component{
        width:100%;
        float:none;
    }
}

