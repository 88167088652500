.aside{
    display: inline-block; 
    width:50%;
    vertical-align:top;
    box-sizing: border-box;
}
.image-login{
    background-image: url('./../../assets/images/login-image.png');
    height:1300px;
    background-size:cover;            
    background-position:center;      
    background-color:#5d7979;
    background-blend-mode:  soft-light;   
}
.parent{
    background-color:rgb(3, 57, 73);
}
.form-login{
    width:80%;
    display: block;
    margin:0px auto;
    border:1px solid rgb(219, 217, 217);
    padding:40px;
    border-radius:8px;
    background-color:#fff;
}
.form-login h1{
    text-align: center;
    font-weight: lighter;
}
.title-span{
    display: block;
    margin:15px auto;
    text-align: center;
    font-size:32px;
    color:#fff;
    margin-top: 200px;
}
.aside-element{
    display: inline-block;
    width:50%;
    box-sizing: border-box;
    text-align: center;
}

.aside-element span{
    text-decoration: none;
    cursor:default
}
.aside-forgot{
    text-align: right;
}
.input-show:hover{
    cursor:pointer;
    -webkit-box-shadow: 1px 2px 17px 3px rgba(0,0,0,0.53); 
    box-shadow: 1px 2px 17px 3px rgba(0,0,0,0.53);
}
.span-register{
    display:block;
    margin:0px auto;
    text-align:center;
    cursor:pointer;
    color:rgb(3, 57, 73);
    text-shadow: 1px 1px 1px rgb(195, 222, 230);
    text-decoration: none;
}
.rotate-component{
    animation:flipV 0.5s linear;
}

.aside-left{
    animation:appears 1.5s linear;
}

@keyframes flipV{
    50%{
        transform:rotateY(90deg);
    }
    to{
        transform:rotateY(180deg);
    }
}

@keyframes appears{
    0%{
        opacity:0;
    }
    to{
        opacity:1;
    }
}

.error-message{
    color:#f598a6;
    text-align: center;
    font-size:14px;
}
.send-email{
    display: block;
    margin:10px auto;
    color:rgb(3, 57, 73);
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width:522px){
    .aside-element {
        font-size: 12px;
    }
}

@media only screen and (max-width:879px){
    .form-login {
        width: 85%;
        display: block;
        margin: 0px auto;
        border: 1px solid rgb(219, 217, 217);
        padding: 20px;
        border-radius: 8px;
        background-color: #fff;
    }
    .aside{
        width:100%;
        min-height:900px;
    }
    .image-login{
        display: none;
    }
}

@media only screen and (max-width:1025px){
    .form-register h1 {
        text-align: center;
        margin:0px!important;
    }

}