.footer{
    padding:20px;
    background-color:rgb(3, 57, 73);
    color:rgb(195, 222, 230);
    text-align: center;
    vertical-align: bottom;
    width:100%;
}

@media only screen and (max-width: 1318px) {
    .footer {
      padding:20px 0px;
    }
    .footer p{
        line-height:1.6;
      }
}