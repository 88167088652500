.parent-modal{
    position:absolute;
    left:0;
    width:100%;
    padding:150px 30px;
    min-height: 100vh;
    background: rgba(23, 26, 24,0.8);
    transition: all .10s;
    top:0px;
    z-index:1;
    animation: appears 2s;
}
.child-modal{
    background-color:#fff;
    width:60%;
    margin:0px auto;
    padding:30px;
    border-radius:4px;
}
.title-modal{
    text-align: center;
    font-size:24px;
    margin:24px;
    color:rgb(195, 222, 230);
}
.close-modal{
    text-align:right;
}
.icon-close{
    font-size:23px;
    cursor:pointer;
    color:rgb(3, 57, 73);
}
.successs-message{
    color:rgb(15, 119, 101);
    text-align: center;
}
@keyframes appears{
    from {opacity:0;}
    to {opacity:1;}
}

@media only screen and (max-width:1024px){
    .parent-modal{
        padding:150px 0px;
    }
    .child-modal{
        width:77%;
    }
}