* {
	margin: 0;
	padding: 0;
  }
  .section-loading {
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color:rgb(201, 225, 233);
  }
  h1{
	margin-right:30px;
	color:rgb(3, 57, 73);
	font-size:40px;
  }
  .section-loading ul.list-bars {
	list-style: none;
	display: flex;
	align-items: center;
  }
  
  .section-loading ul.list-bars li {
	margin: 0 7px;
	width: 8px;
	border-radius: 10px;
	background-color: rgb(3, 57, 73);
	animation: animate 0.8s infinite alternate;
  }
  .section-loading ul.list-bars li:nth-child(odd) {
	height: 80px;
	animation-delay: 0.6s;
  }
  .section-loading ul.list-bars li:nth-child(even) {
	height: 20px;
  }
  
  @keyframes animate {
	from {
	  height: 5px;
	}
	to {
	  height: 70px;
	}
  }
  