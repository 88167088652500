.tabs-parent{
    text-align: right;
    background-color:rgba(195, 222, 230,0.2);
}
.icon-tab{
    cursor:pointer;
    color:rgb(3, 57, 73);
    background-color: rgb(3, 57, 73);
    padding:5px 10px;
    color:rgb(195, 222, 230);
}