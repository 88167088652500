.container-loader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.loader {
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  display: block;
  margin: 10px auto 0;
  position: relative;
  color: #FFF;
  transform: translateY(30px);
  box-sizing: border-box;
  animation: animloader 2s ease infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  color: #FFF;
  position: absolute;
  left: 30px;
  top: 0px;
  animation: animloader 2s 0.2s ease infinite;
}
.loader::before {
  animation-delay: 0.4s;
  left: 60px;
}

@keyframes animloader {
  0% {
    top: 0;
    color: rgb(3, 57, 73);
  }
  50% {
    top: 30px;
    color: rgb(235, 234, 234);
  }
  100% {
    top: 0;
    color: rgb(3, 57, 73);
  }
}
    
