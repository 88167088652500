.menu-responsive{
    text-align:right;
    background-color: rgb(255, 255, 255);
    display:none;
}

.menu-responsive ul{
    width:100%;
    text-align: center;
    background-color:rgb(235, 234, 234);
    padding:0px 0px 20px;
}
.menu-responsive div{
    text-align:right;
    margin-right:10px;
}
.menu-responsive div .icon-close-menu{
    margin:10px;
    font-size:20px;
}
.menu-responsive ul a{
    text-decoration: none;
    color:rgb(3, 57, 73);
}
.active-responsive{
    background-color:rgb(195, 222, 230);
    padding:6px;
}
.menu-responsive ul li{
    margin-bottom:10px;
}
.icon-menu-responsive{
    margin-right:10px;
    color:rgb(3, 57, 73);
}
.icon-bars-menu {
    margin: 5px;
    font-size: 30px;
    margin-right: 15px;
}
.menu-responsive ul{
    display:none;
}

.dropdown_item-1{
    transform-origin: top center;
    animation: scaleZ 300ms 60ms ease-in-out forwards;
}
.dropdown_item-2{
    transform-origin: top center;
    animation: scaleZ 300ms 120ms ease-in-out forwards;
}
.dropdown_item-3{
    transform-origin: top center;
    animation: scaleZ 300ms 180ms ease-in-out forwards;
}
.dropdown_item-4{
    transform-origin: top center;
    animation: scaleZ 300ms 240ms ease-in-out forwards;
}
.dropdown_item-5{
    transform-origin: top center;
    animation: scaleZ 300ms 300ms ease-in-out forwards;
}
.dropdown_item-6{
    transform-origin: top center;
    animation: scaleZ 300ms 360ms ease-in-out forwards;
}
.dropdown_item-7{
    transform-origin: top center;
    animation: scaleZ 300ms 420ms ease-in-out forwards;
}
.dropdown_item-8{
    transform-origin: top center;
    animation: scaleZ 300ms 480ms ease-in-out forwards;
}
.dropdown_item-9{
    transform-origin: top center;
    animation: scaleZ 300ms 540ms ease-in-out forwards;
}

.dropdown_item-10{
    transform-origin: top center;
    animation: scaleZ 300ms 600ms ease-in-out forwards;
}

@keyframes scaleZ {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    
    80% {
      transform: scale(1.07);
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
    }
}

@media only screen and (max-width: 1318px) {
    .menu-responsive {
      display: block;
    }
}