.guilds-section-data{
    display:none;
}

.each-operator-div{
    border: 1px solid #e3e3e3;
    padding:10px;
}

.each-operator-div:nth-child(odd){
    background-color:rgb(241, 251, 251);
}
.operators-type{
    margin:20px 30px;
    border:1px solid #e3e3e3;
    padding:15px;
    border-radius:3px;
}
.second-title{
    text-align:center;
    margin-top:10px;
    font-size:15px;
}
.each-type{
    display: block;
    margin:15px;
    border-bottom: 1px solid #efeeee;
}

.guilds-section-data{
    display:none;
    padding:10px;
    border:1px solid #e3e3e3;
    margin-top:10px;
    animation: appears 1s;
    padding-bottom:15px;
}
.fade-out{
    animation: fadeout 0.3s forwards;
}
@keyframes fadeout{
    to {
        opacity:0;
    }
}
@keyframes appears{
    from {opacity:0;}
    to {opacity:1;}
}
.each-operator-div:nth-child(odd) .guilds-section-data{
    border:1px solid rgb(225, 225, 225);
}
.div-close{
    text-align:right;
}

.insert-type-form{
    margin-top:45px!important;
    background-color:rgb(244, 244, 244);
    box-sizing: border-box;
    padding:27px;
    border-radius:4px;
}

@media only screen and (max-width: 425px) {
    .each-operator-div{
        text-align:center;
    }
    .each-operator-div .right{
        display: block;
        float:none;
        margin:10px auto!important;
        width:70%;
        padding:10px
    }
}

@media only screen and (max-width: 1024px) {
    .all-users-section{
        width:87%!important;
    }
}