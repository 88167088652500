.content-data{
    display:inline-block;
    width:45%;
    border:1px solid #e3e3e3;
    border-radius: 6px;
    margin-top:15px;
    margin-bottom:15px;
    margin-left:10px;
    -webkit-box-shadow: 4px 4px 5px -2px #e3e3e3;
    box-shadow: 4px 4px 5px -2px #e3e3e3;
    background-color:#fff;
    box-sizing: content-box;
}
.content-data form{
    margin:20px 30px;
}
.content-data h1{
    font-size:16px;
    margin:20px 8px;
}
.change-passsword-button{
    display:inline;
    float:right;
    background-color: rgb(3, 57, 73);
    color: rgb(220, 239, 245);
    border: 2px solid rgb(3, 57, 73);
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
}

.change-passsword-button:hover {
    background-color: rgb(220, 239, 245);
    color: rgb(3, 57, 73);
}

.change-passsword-button:active {
    transform: scale(0.95);
}

.info-button{
    color:rgb(3, 57, 73);
    background-color: rgb(221, 235, 239);
    border:1px solid rgb(3, 57, 73);
}
.read-only{
    transition: 0.5s all;
}
.read-only:read-only{
    background-color:#eeeded;
}
.double-tab{
    margin-left:1px;
}

.change-password-div{
    animation: expand .5s ease-in-out;
    margin-left: 20px;
}

.shrink{
    animation: shrink .5s ease-in;
}

@keyframes expand {
    from {
      transform: scale(0);
      opacity: 0;
    }
}

@keyframes shrink {
    from {
        transform: scale(1);
        opacity: 1;
    }
    to {
        transform: scale(0);
        opacity: 0;
    }
}

@media only screen and (max-width: 1318px) {
    .content-data{
        width:47%;
    }
}

@media only screen and (max-width: 425px) {
    .each-user-name{
        text-align:center;
    }
    .each-user-div .right{
        display: block;
        float:none;
        margin:10px auto!important;
        width:70%;
        padding:10px
    }
}

@media only screen and (max-width:732px){
    .content-data{
        display: block;
        width:85%!important;
        margin:0px auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .change-password-div{
        margin-top:15px;
        margin:0px auto;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .guilds-section form{
        margin:10px 10px;
    }
    .content-data form {
        margin: 20px 10px;
    }
}