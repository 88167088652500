.content-data{
    background-color:#fff;
    padding:20px;
    margin:10px;
    display: inline-block;
    width: 45%;
    border: 1px solid #e3e3e3;
    border-radius: 6px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px;
    box-shadow: 4px 4px 5px -2px #e3e3e3;
    background-color: #fff;
    box-sizing: content-box;
}
.each-user-div{
    border: 1px solid #e3e3e3;
    padding:10px;
}

.each-user-div:nth-child(odd){
    background-color:rgb(241, 251, 251);
}

.user-section-data{
    display:none;
    padding:10px;
    border:1px solid #e3e3e3;
    margin-top:10px;
    animation: appears 1s;
    padding-bottom:15px;
}

.each-user-div:nth-child(odd) .user-section-data{
    border:1px solid rgb(225, 225, 225);
}
.div-close{
    text-align:right;
}

@keyframes appears{
    from {opacity:0;}
    to {opacity:1;}
}

@media only screen and (max-width: 1024px) {
    .all-users-section{
        width:87%!important;
    }
}